.p-dialog-header {
    background-color: #142f47 !important;
    color: #fff !important;
    font-size: x-large;
}

.p-dialog-content {
    font-size: large;
    margin-top: 2%;
}

.p-dialog-default {
    background-color: #fff;
}