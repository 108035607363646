/* Estilos para iPhones en general */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .loginSize {
        margin-top: 0px !important;
        height: 700px !important;
    }
}

/* Estilos para iPads en general */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .loginSize {
        margin-top: 30px !important;
        margin-left: 0px !important;
        width: 800px !important;
        background-color: white;
        height: 965px !important;
        font-size: 50px !important;
    }

    .loginImage {
        width: 600px !important;
        height: 600px !important;
        margin-left: 14% !important;
    }

    .p-card-title {
        /* background-color: white !important; */
        font-size: 50px !important;
    }

    .loginButton {
        font-size: 35px !important;
    }

    .p-card-subtitle {
        font-size: 40px !important;
    }
}

/* Estilos para pantallas con resolución de 1280 píxeles en adelante */
@media screen and (min-width: 1280px) {
    .loginSize {
        margin-top: 30px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        width: 80% !important;
        background-color: white;
        height: 100% !important;
        font-size: 50px !important;
    }

    .loginImage {
        width: 600px !important;
        height: 600px !important;
        margin-left: 30% !important;
    }

    .p-card-title {
        /* background-color: white !important; */
        font-size: 40px !important;
    }

    .loginButton {
        font-size: 30px !important;
    }

    .p-card-subtitle {
        font-size: 36px !important;
    }

}