/* Estilos para iPhones 14 pro max en general */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .scrollsize {
        height:550px !important;
    }
    .scrollsizeLogin{
        height: 680px !important;
    }
  }
  /* Estilos para iPhones en general */
@media only screen and (min-device-width: 320px) and (max-device-width: 400px) {
    .scrollsize {
        height:460px !important;
    }
    .scrollsizeLogin{
        height: 600px !important;
    }
  }

  /* Estilos para iPads en general */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .scrollsize {
        height: 890px !important;
        width: 800px !important;
    }
    .scrollsizeLogin{
        height: 1000px !important;
    }
  }

  /* Estilos para pantallas con resolución de 1280 píxeles en adelante */
@media screen and (min-width: 1280px) {
    .scrollsize {
        height: 810px !important;
        width: 103% !important;
    }
    .scrollsizeLogin{
        height: 960px !important;
    }

}