@font-face {
  font-family: "BoingBold0";
  src: local("BoingBold0"),
    url("./assets/Fonts/Boing-Regular.ttf") format("truetype");
  font-weight: bold;
}

/* @media only screen and (max-width:414px)and (max-height: 915px) {
  #footer {
    margin-top: 250px;

  }
}

@media only screen and (min-width:768px)and (max-height: 1180px) {
  #footer {
    margin-top: 490px;

  } */
/* } */

/* 


@media only screen and (width:912px)and (height: 1368px) {
  #footer {
    margin-top: 240px;

  }
}

@media only screen and (width:412px)and (height: 914px) {
  #footer {
    margin-top: 0px;

  }
}

@media only screen and (height: 969px) {
  #footer {
    margin-top: 0px;

  }
} */

body {
  font-family: "BoingBold0";
}

div {
  font-family: "BoingBold0";
}

span {
  font-family: "BoingBold0";
}

h1 {
  font-family: "BoingBold0";
}

h2 {
  font-family: "BoingBold0";
}

h3 {
  font-family: "BoingBold0";
}

h4 {
  font-family: "BoingBold0";
}

h5 {
  font-family: "BoingBold0";
}

button {
  font-family: "BoingBold0";
}

label {
  font-family: "BoingBold0";
}

input {
  font-family: "BoingBold0";
}

ol {
  font-family: "BoingBold0";
}

ul {
  font-family: "BoingBold0";
}

li {
  font-family: "BoingBold0";
}

p {
  font-family: "BoingBold0";
}

label {
  font-family: "BoingBold0";
}