/* Estilos para iPhones en general */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .footerSize {
      height: 0px !important;
    }
  }

  /* Estilos para iPads en general */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .footerSize {
        height: 0px !important;
        font-size: 25px !important;
        margin-top: 0px !important;
        
      }
  }
  /* Estilos para pantallas con resolución de 1280 píxeles en adelante */
  @media screen and (min-width: 1280px) {
    .footerSize {
        height: 0px !important;
        font-size: 20px !important;
        margin-top: 0px !important;
      }

}